const menuData = [
  {
    id: 420,
    title: "Home",
    path: "/#home",
    newTab: false,
  },
  {
    id: 1,
    title: "Recap",
    path: "/#Recap",
    newTab: false,
  },
  {
    id: 2,
    title: "Sponsors",
    path: "/#Sponsorships",
    newTab: false,
  },
  {
    id: 69,
    title: "Prizes",
    path: "/#Prizes",
    newTab: false,
  },
  // {
  //   id: 33,
  //   title: "Speakers",
  //   path: "/#Speakers",
  //   newTab: false,
  // },
  {
    id: 5,
    title: "Schedule",
    path: "/#Schedule",
    newTab: true,
  },
  {
    id: 3,
    title: "Team",
    path: "/#Team",
    newTab: false,
  },
  {
    id: 3,
    title: "FAQ",
    path: "/#Faq",
    newTab: false,
  },


];
export default menuData;
